export default {
  async selectSyncUpload (Vue, idsyncUpload) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.sync_upload)
      .where(tables.sync_upload.idsync_upload.eq(idsyncUpload))
      .exec())[0]
  },
}
